
import { defineComponent } from 'vue';
import gsap from 'gsap';
export default defineComponent({          
    setup() {

        const navLinks = [
            {id: 0, routeName: "Home", routeText: "Home"},
            {id: 1, routeName: "Historia", routeText: "História"},
            {id: 2, routeName: "Eventos", routeText: "Eventos"},
            {id: 3, routeName: "Tetos", routeText: "Tetos"},
            {id: 4, routeName: "Discografia", routeText: "Discografia"},
            {id: 5, routeName: "Contactos", routeText: "Contactos"},
        ]

        const beforeEnter: any = (el: any) => {
            el.style.opacity = 0;
            el.style.transform = 'translateX(-100px)';
        }

        const enter: any = (el: any, done: any) => {
            gsap.to(el, {
                opacity: 1,
                x: 0,
                duration: 0.2,
                onComplete: done,
                delay: el.dataset.index * 0.05,
            });
        }

        return { navLinks, beforeEnter, enter }

    },
    name: "MobileNav",
    data() {
        return {
            toggle: false,
            newClass: "",
            isActive: "",
        }
    },
    methods: {
        toggleClass() {
            this.toggle = !this.toggle;
            this.toggle ? this.newClass = "toggle" : this.newClass = "";
            this.toggle ? this.isActive = "isActive" : this.isActive = "";
        },
    },
});
