
import { defineComponent } from 'vue';
import Navbar from '../components/Navbar.vue';
import NavbarMobile from '../components/NavbarMobile.vue';
import Hero from '../components/Hero.vue';
import ApeitaBtn from '../components/ApeitaBtn.vue';
import ScrollToTopButton from '../components/ScrollToTopButton.vue';
import TetoCard from '../components/TetoCard.vue';
import FeatureCard from '../components/FeatureCard.vue';
import Footer from '../components/Footer.vue';

export default defineComponent({
  name: 'Home',
  data() {
    return {
      activatedNavbar: false,
      isAtTop: true,
      mobileMode: false,
      tetosReviews: [
        {id: 1, imgPath: "serodio.webp", quote: "É teres sempre alguém pronto a ajudar quando precisas, é estares sempre rodeado de boa música, é teres o melhor que a UÉ pode oferecer!", author: 'Nuno "Caçador" Serôdio' },
        {id: 2, imgPath: "seixas.webp", quote: "Honestamente, lembro-me de pouca coisa, mas o fígado tem cá umas marcas.", author: 'João "Wolverine" Seixas' },
        {id: 3, imgPath: "rodrigo.webp", quote: "É (tentar) fazer o curso sabendo que toda e qualquer noite é noite de copos, amigos e modas (alentejanas e não só!)! #fazercadeirasnobilhar", author: 'Rodrigo "TGV" Silva' },
      ],
      features: [
        {id: 1, icon: "fa fa-music", subtitle: "As Modinhas", description: "Aquisição e desenvolvimento de novas competências e conhecimentos musicais e a comemoração da cultura alentejana e das tradições da academia eborense."},
        {id: 2, icon: "fa fa-users", subtitle: "A Camaradagem", description: "Experiências, histórias, memórias e aventuras únicas e originais das viagens pelo país e estrangeiro. Um grupo de amigos que estará sempre disposto a ajudar, nos bons e maus momentos"},
        {id: 3, icon: "fa fa-beer", subtitle: "O Vinho", description: "Celebração daquilo que temos de mais belo nesta vida: a capa, a batina, a guitarra, o amor, a açorda, o cante alentejano e o vinho. Para cantar basta beber um copo, e ao cantar ultrapassamos as amarguras da vida."},
      ]
    }
  },
  components: {
    Navbar,
    NavbarMobile,
    ApeitaBtn,
    ScrollToTopButton,
    TetoCard,
    FeatureCard,
    Hero,
    Footer,
  },
  created() {
    window.addEventListener('scroll', this.handleScroll);
    this.handleResize();
    window.addEventListener('resize', this.handleResize);
  },
  unmounted() {
    window.removeEventListener('scroll', this.handleScroll);
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    getImgURL(image: String) {
      return require('../assets/media/' + image);
    },
    scrollToElement(destination: string) {
      const element = document.querySelector(destination);

      if (element) {
        element.scrollIntoView({behavior: 'smooth'});
      }
    },
    handleScroll () {
      window.pageYOffset >= 250 ? this.isAtTop = false : this.isAtTop = true;
    },
    handleResize () {
      this.mobileMode = window.innerWidth <= 1015;

      if(!this.mobileMode) {
        this.activatedNavbar = false;
      } 
    },
  },
});
