
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'ApeitaBtn',
    props: {
    callToAction: String,
    },
    methods: {
        scrollToElement(destination: string) {
            const element = document.querySelector(destination);

            if (element) {
                element.scrollIntoView({behavior: 'smooth'});
            }
        }
    },    

});
