

import { defineComponent } from 'vue';

export default defineComponent({
  name: 'TetoCard',
  props: {
      ImgPath: String,
      quote: String,
      author: String,
  }
});
