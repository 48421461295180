

import { defineComponent } from 'vue';
import ApeitaBtn from '../components/ApeitaBtn.vue';
import DownButton from '../components/DownButton.vue';

export default defineComponent({
  name: 'Hero',
  props: {
      destination: String,
      backgroundImage: String,
      title: String,
      subtitle: String,
      callToAction: String,
  },
  components: {
      ApeitaBtn,
      DownButton,
  },
  methods: {
      scrollToElement(destination: string) {
      const element = document.querySelector(destination);

      if (element) {
        element.scrollIntoView({behavior: 'smooth'});
      }
    },
  },
  computed: {
      style () :String {
        return `background-image: url(${this.backgroundImage})`;
      }
    },
});
