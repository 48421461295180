

import { defineComponent } from 'vue';

export default defineComponent({
  name: 'FeatureCard',
  props: {
      icon: String,
      title: String,
      description: String,
  }
});
